import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const description = "Meet tio's digital products which all aims to make your life better by supporting it with technology."

const OurProducts = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="our products" description={description} canonical="https://tio.ist/our-products/" />

    <div className=" relative py-10">
      <div className="container text-2xl text-center mx-auto  lg:px-10 max-w-2xl">
        <h1 className="text-5xl p-10 font-bold text-blue">Our Products</h1>
        <p className="p-2 font-light">
          With our experience and unique solution oriented approach, we
          transform the solutions we produce for businesses into software,
          applications and technology-supported tools, and we always continue to
          develop them.
        </p>
        <p className="pt-6 px-2 font-light">Our products have one purpose:</p>
        <p className="pb-4 px-2 font-light">
          Making Your Life Tech‐Supported
        </p>
        <p className="pt-4 px-2 font-light">
          We get inspired by life itself and create digital products for real
          life solutions.
        </p>
        <p className="pb-2 px-2 font-light">Not the other way around.</p>

        <div className="mx-auto p-20 text-center ">
          <span className="p-10">
            <StaticImage
              className="bounce"
              width={30}
              src="../images/arrow.png"
              alt="Before / After tio"
            />
          </span>
        </div>
      </div>

      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/7islem-logo.png"
              alt="7işlem"
            />
          </span>
        </div>
        <div className="mx-auto text-center w-full">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images/7islem-banner.jpg"
              alt="7işlem"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
          <span className="font-bold">7işlem</span> is an end-to-end management
          software that enables D2C brands to work 10x more efficiently by
          managing their e-commerce, retail, marketing, accounting and
          manufacturing processes from a single platform. It includes 3 digital
          products inside; business management, sale management and product
          management. All including products can be integrated together to make
          a business’ all financial and operational processes digital powered
          and more fluent. In this way, your brand’s all fundamental and
          advanced functions are gathered on a single platform.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://7islem.com"
        >
          www.7islem.com ↗{" "}
        </a>
      </div>

      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/hipodoc-logo.png"
              alt="Hipodoc"
            />
          </span>
        </div>
        <div className="mx-auto text-center ">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images/hipodoc-banner-en.jpg"
              alt="Hipodoc"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
          <span className="font-bold">Hipodoc</span> is a case-focused recording
          and sharing pool for all medical practionaries, physicians,
          dietitians, dentists and veterinarians. As a health informatics
          product, Hipodoc enables medical professionals to record and archive
          cases easily by supporting them with notes, photos and videos. You can
          always access the cases you have archived from any device, and you can
          share the cases with other colleagues if you wish. You can reach the
          experiences of other professionals, ask questions and consult.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://hipodoc.com"
        >
          www.hipodoc.com ↗{" "}
        </a>
      </div>
      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/cember-logo.png"
              alt="Çember"
            />
          </span>
        </div>
        <div className="mx-auto text-center ">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images/cember-banner-en.jpg"
              alt="Çember"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
          <span className="font-bold">Çember</span> is a social microfinancing
          app that allows users to save as groups. A circle occurs from
          relatives, friends or family members who trust each other and share
          motivation for the same dream or amount of saving. Each month, one of
          the users in the circle receives the monthly payment from the others
          until everyone reaches their share. You can save and spend without
          going into debt with the proven saving method ROSCA, applied to
          digital. You can visualize your dreams and create a social commitment
          to save easier with support of Çember.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://cemberapp.com"
        >
          www.cemberapp.com ↗{" "}
        </a>
      </div>
    </div>
  </Layout>
)

export default OurProducts
